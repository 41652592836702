import { Translate } from '../components/translate';

export const PRODUCT_GROUPS_NAMES = {
  pharma: 'Лекарственные средства',
  alcohol: 'Алкогольная продукция',
  tobacco: 'Табачная продукция',
  water: 'Вода и прохладительные напитки',
  beer: 'Пиво и пивные напитки',
  appliances: 'Бытовая техника',
  antiseptic: 'Спиртосодержащая непищевая продукция',
  medical_products: 'Изделия медицинского назначения',
  medicals: 'Изделия медицинского назначения',
};

export const packageTypes = {
  consumer: '0',
  group: '1',
};

export const PRODUCT_GROUPS_LIST = [
  {
    value: 'lp',
    label: Translate(PRODUCT_GROUPS_NAMES.lp),
    countries: ['KZ', 'AM'],
    permittedTypesOfPackaging: [packageTypes.consumer],
  },
  {
    value: 'shoes',
    label: Translate(PRODUCT_GROUPS_NAMES.shoes),
    countries: ['KZ', 'AM'],
    permittedTypesOfPackaging: [packageTypes.consumer],
  },
  {
    value: 'tobacco',
    label: Translate(PRODUCT_GROUPS_NAMES.tobacco),
    countries: ['KZ', 'KG', 'UZ'],
    permittedTypesOfPackaging: [packageTypes.consumer, packageTypes.group],
  },
  {
    value: 'pharma',
    label: Translate(PRODUCT_GROUPS_NAMES.pharma),
    countries: ['KZ', 'UZ'],
    permittedTypesOfPackaging: [packageTypes.consumer],
  },
  {
    value: 'milk',
    label: Translate(PRODUCT_GROUPS_NAMES.milk),
    countries: ['KZ'],
    permittedTypesOfPackaging: [packageTypes.consumer, packageTypes.group],
  },
  {
    value: 'alcohol',
    label: Translate(PRODUCT_GROUPS_NAMES.alcohol),
    countries: ['KZ', 'KG', 'UZ'],
    permittedTypesOfPackaging: [packageTypes.consumer, packageTypes.group],
  },
  {
    value: 'water',
    label: Translate(PRODUCT_GROUPS_NAMES.water),
    countries: ['KZ', 'UZ'],
    permittedTypesOfPackaging: [packageTypes.consumer, packageTypes.group],
  },
  {
    value: 'beer',
    label: Translate(PRODUCT_GROUPS_NAMES.beer),
    countries: ['UZ'],
    permittedTypesOfPackaging: [packageTypes.consumer, packageTypes.group],
  },
  {
    value: 'appliances',
    label: Translate(PRODUCT_GROUPS_NAMES.appliances),
    countries: ['UZ'],
    permittedTypesOfPackaging: [packageTypes.consumer],
  },
  {
    value: 'antiseptic',
    label: Translate(PRODUCT_GROUPS_NAMES.antiseptic),
    countries: ['UZ'],
    permittedTypesOfPackaging: [packageTypes.consumer, packageTypes.group],
  },
  {
    value: 'medicals',
    label: Translate(PRODUCT_GROUPS_NAMES.medicals),
    countries: ['UZ'],
    permittedTypesOfPackaging: [packageTypes.consumer, packageTypes.group],
  },
];

export const MARK_CODE_TYPES = [
  { value: '0', label: Translate('КМ для потребительской упаковки') },
  { value: '1', label: Translate('КМ для групповой упаковки') },
];

export const MARK_CODE_TYPES_NAMES = {
  0: 'КМ для потребительской упаковки',
  UNIT: 'КМ для потребительской упаковки',
  1: 'КМ для групповой упаковки',
  GROUP: 'КМ для групповой упаковки',
};

export const PRODUCT_GROUPS = {
  milk: 'milk',
  pharma: 'pharma',
  alcohol: 'alcohol',
  shoes: 'shoes',
  tobacco: 'tobacco',
  lp: 'lp',
  appliances: 'appliances',
  beer: 'beer',
  water: 'water',
  antiseptic: 'antiseptic',
  medicals: 'medicals',
};

export const DOCUMENT_TYPES = {
  AGGREGATION: 'AGGREGATION',
  UTILISATION: 'UTILISATION',
  DROPOUT: 'DROPOUT',
  VALIDATION: 'VALIDATION',
  TRANSPORT_CODE_DISAGGREGATION: 'TRANSPORT_CODE_DISAGGREGATION',
};

export const DOCUMENT_TYPES_NAMES = {
  [DOCUMENT_TYPES.order]: Translate('Заказ КМ'),
  [DOCUMENT_TYPES.AGGREGATION]: Translate('Агрегация'),
  [DOCUMENT_TYPES.UTILISATION]: Translate('Нанесение'),
  [DOCUMENT_TYPES.DROPOUT]: Translate('Списание (отбраковка)'),
  [DOCUMENT_TYPES.VALIDATION]: Translate('Валидация'),
  [DOCUMENT_TYPES.TRANSPORT_CODE_DISAGGREGATION]: Translate('Дезагрегация КИТУ'),
};

export const DOCUMENT_TYPES_LIST = [
  {
    value: DOCUMENT_TYPES.AGGREGATION,
    label: DOCUMENT_TYPES_NAMES[DOCUMENT_TYPES.AGGREGATION],
  },
  {
    value: DOCUMENT_TYPES.UTILISATION,
    label: DOCUMENT_TYPES_NAMES[DOCUMENT_TYPES.UTILISATION],
  },
  {
    value: DOCUMENT_TYPES.DROPOUT,
    label: DOCUMENT_TYPES_NAMES[DOCUMENT_TYPES.DROPOUT],
  },
  {
    value: DOCUMENT_TYPES.VALIDATION,
    label: DOCUMENT_TYPES_NAMES[DOCUMENT_TYPES.VALIDATION],
  },
  {
    value: DOCUMENT_TYPES.TRANSPORT_CODE_DISAGGREGATION,
    label: DOCUMENT_TYPES_NAMES[DOCUMENT_TYPES.TRANSPORT_CODE_DISAGGREGATION],
  },
];
