import * as R from 'ramda';
import fp from 'lodash/fp';
// eslint-disable-next-line import/no-named-as-default
import routes from '../routes';
import faviconKZ from '../assets/favicons/faviconKZ.ico';
import faviconUZ from '../assets/favicons/faviconUZ.ico';
import faviconRU from '../assets/favicons/fiviconRU.ico';
import faviconAM from '../assets/favicons/faviconAM.ico';
import favicon from '../assets/favicons/favicon.ico';

export const path = (data) => (p) => R.flip(R.path)(data, p.split('.'));

// eslint-disable-next-line no-unused-vars
const routesTitles = [
  { link: routes.auth(), title: 'Вход' },
  { link: routes.aggregations(), title: 'Агрегация' },
  { link: routes.documents(), title: 'Отчеты' },
  { link: routes.orders(), title: 'Заказы' },
  { link: routes.marking(), title: 'Нанесение' },
  { link: routes.rejections(), title: 'Отбраковка' },
  { link: routes.guides(), title: 'Руководства' },
  { link: routes.products(), title: 'Товары' },
  { link: routes.devices(), title: 'Устройства' },
  { link: routes.stickers(), title: 'Этикетки' },
  { link: routes.profile(), title: 'Профиль' },
  { link: routes.serviceProviders(), title: 'Сервис-провайдеры' },
  { link: routes.serviceDistributions(), title: 'Сети распределения' },
  { link: routes.disaggregation(), title: 'Дезагрегация КИТУ' },
];

export const HELPERS = {
  getPageTitle: (url) => (url === '/'
    ? 'Домашняя страница'
    : fp.pipe(
      fp.find((item) => url.includes(item.link)),
      fp.get('title'),
    )(routesTitles)),
  parseErrors: (errors) => {
    if (errors && errors.errors && errors.errors.length !== undefined) {
      return fp.map(
        (v) => v.defaultMessage,
      )(errors.errors).join(', ');
    }
    return errors && (errors.message || errors.error_description);
  },
  isPhone: (data) => (/^((8|\+7)[-]?)?(\(?\d{3}\)?[-]?)?[\d-]{10,10}$/).test(data),
};

const getFaviconByCountry = (country) => {
  switch (country) {
    case 'KZ': return faviconKZ;
    case 'KG': return favicon;
    case 'RU': return faviconRU;
    case 'AM': return faviconAM;
    case 'UZ': return faviconUZ;
    default: return faviconAM;
  }
};

export const setFavicon = (countryCode) => {
  const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
  link.type = 'image/x-icon';
  link.rel = 'shortcut icon';
  link.href = getFaviconByCountry(countryCode);
  document.getElementsByTagName('head')[0].appendChild(link);
};

export default {
  path,
};

export const setFieldsValue = ([name], state, { changeValue }) => {
  changeValue(state, name[0], () => name[1]);
};

export function generateGuid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  return `${s4() + s4()}-${s4()}-${s4()}-${
    s4()}-${s4()}${s4()}`;
}

const getTypeList = (pgList) => fp.pipe(
  fp.map((item) => item.types),
  fp.flatten,
  fp.uniq,
)(pgList);

export const isMpOperator = (pgList) => getTypeList(pgList).includes('IS_MP_OPERATOR');
