import moment from 'moment';
import { PRODUCT_GROUPS_NAMES } from '../../constants';
import i18n from '../../i18n';
import types from '../types';
import { STATUSES } from '../../pages/validation/contants';

const initialState = {
  item: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.DISAGGREGATION_SET_ITEM:
      return {
        ...state,
        item: parseItem(action.payload),
      };
    default:
      return state;
  }
};

const parseItem = (data) => ({
  ...data,
  auto_report: data?.isAutoReport,
  pgName: i18n.t(PRODUCT_GROUPS_NAMES[data.pg]),
  createdTimestamp: moment(data.createdTimestamp).format('DD.MM.YYYY HH:mm:ss'),
  statusName: STATUSES?.[data.status] ?? '',
});

export default reducer;
