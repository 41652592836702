import { put } from 'redux-saga/effects';
import { setIsLoading } from '../actions/common';
import API from '../../api';
import { addError } from '../actions/errors';
import { history } from '../../store';
import { routes } from '../../routes';
import { setItem } from '../actions/disaggregation';

export function* loadDisaggregationItemSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const json = yield API.rest.reports.item(payload.id);
    yield put(setItem({ ...json }));
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
  }
}

export function* createDisaggregationSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    yield API.rest.reports.disaggregation(payload.data, payload.query);
    yield put(setIsLoading(false));
    yield history.push(routes.documents());
  } catch (err) {
    yield put(setIsLoading(false));
    yield put(addError(err));
  }
}
