import fp from 'lodash/fp';
import Cookies from 'js-cookie';
import { PRODUCT_GROUPS_LIST, PRODUCT_GROUPS_NAMES } from '../constants';
import { COUNTRIES } from '../constants/countries';
import { Translate } from '../components/translate';

export const getPgListByCountry = (country) => {
  const token = getToken()?.split('.')[1];
  if (!token) return [];
  const payloadObj = JSON.parse(b64DecodeUnicode(token));
  const suzProductGroup = (window.env.SUZ_PRODUCT_GROUPS || '').split(',');
  const availablePg = payloadObj.product_group_info
    .filter((el) => el.status === '5' || el.status === '4')
    .filter((el) => suzProductGroup.indexOf(el.name) === -1)
    .map((item) => item.name);
  return PRODUCT_GROUPS_LIST
    .filter(
      (item) => item.countries.includes(country),
    )
    .filter(
      (item) => availablePg.includes(item.value),
    );
};

export const parsePgList = (list, reportType) => {
  const token = getToken()?.split('.')[1];
  if (!token) return [];
  const payloadObj = JSON.parse(b64DecodeUnicode(token));
  if (reportType === 'disaggregation') {
    return payloadObj.product_group_info.filter((group) => {
      const found = list.find((el) => el.pg === group.name);
      return found?.reportsPermit?.transportCodeDisaggregation ?? false;
    }).map((pg) => ({ value: pg.name, label: Translate(PRODUCT_GROUPS_NAMES[pg.name]) }));
  }
  const suzProductGroup = (window.env.SUZ_PRODUCT_GROUPS || '').split(',');
  return payloadObj.product_group_info
    .filter((el) => el.status === '5' || el.status === '4')
    .filter((el) => suzProductGroup.indexOf(el.name) === -1)
    .filter((pg) => {
      const found = list.find((el) => el.pg === pg.name);
      if (!found) return false;
      if (reportType === 'aggregation') return found.reportsPermit.aggregation;
      if (reportType === 'dropout') return found.reportsPermit.dropout;
      return found.paymentFlow !== 'RESERVE';
    })
    .map((pg) => ({ value: pg.name, label: Translate(PRODUCT_GROUPS_NAMES[pg.name]) }));
};

export const getParamFromToken = (param, json, tokenType = 'access_token') => {
  const data = getTokenData(json, tokenType);
  return data[param];
};

export function getCookie(name) {
  const matches = document.cookie.match(
    new RegExp(
      `(?:^|; )${name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1')}=([^;]*)`,
    ),
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

function deleteCookie(name, domain) {
  document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=${domain}`;
}

export function setCookie(cookieName, value) {
  deleteCookie(cookieName, getCurrentDomain());
  Cookies.set(cookieName, value, {
    domain: getCurrentDomain(),
  });
}

export function getToken() {
  const count = getCookie('count') || 0;
  const token = new Array(+count)
    .fill(1)
    .map((v, i) => getCookie(`tokenPart${i + 1}`))
    .join('');
  return count ? token : null;
}

export const getTokenData = (json, tokenType) => {
  const fullToken = json[tokenType];
  const token = fullToken.split(('.'))[1];
  return JSON.parse(Buffer.from(token, 'base64').toString());
};

export const getShortName = (fullName) => fullName
  .split(' ')
  .map((item, idx) => (idx === 0 ? item : `${item[0]}.`))
  .join(' ');

export const getCountryName = (country) => fp.pipe(
  fp.find((item) => item.value === country),
  fp.get('label'),
)(COUNTRIES);

export const prepareDefaultFilter = (filters) => Object.keys(filters).map(
  (key) => {
    const id = key.includes('Start') ? key.replace('Start', '') : key;
    const value = key.includes('Start')
      ? { startDate: filters[`${id}Start`], endDate: filters[`${id}End`] }
      : filters[key];
    return {
      id,
      value,
    };
  },
).filter((item) => !item.id.includes('End'));

export const b64DecodeUnicode = (str) => {
  const base64 = str.replace(/-/g, '+').replace(/_/g, '/');
  return decodeURIComponent(
    Array.prototype.map
      .call(atob(base64), (c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`)
      .join(''),
  );
};
export function getCurrentDomain() {
  return `${window.location.hostname.split('.').slice(-2).join('.')}`;
}

export function deleteAllCookies() {
  const cookies = document.cookie.split(';');
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = `${name
    }=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=${getCurrentDomain()}`;
  }
  localStorage.clear();
}

export function isAdmin() {
  const token = getToken().split('.')[1];
  const payloadObj = JSON.parse(b64DecodeUnicode(token));
  const { authorities } = payloadObj;
  return authorities.includes('ROLE_ADMIN');
}
