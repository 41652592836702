import { all, takeLatest, put } from 'redux-saga/effects';
import {
  closeOrderSaga,
  createOrderSaga,
  getRetryCodeListSaga,
  loadOrderItemSaga,
  loadOrderListSaga,
  printOrderCodesSaga,
  sendOrderSaga,
} from './orders';
import { clearErrors } from '../actions/errors';
import types from '../types';
import { checkAuthSaga, loginSaga, logoutSaga } from './auth';
import { downloadFileSaga, uploadFileSaga, loadSettingsSaga } from './common';
import {
  createRejectionsSaga, loadRejectionItemSaga, loadRejectionsListSaga, updateDropoutSaga,
} from './rejections';
import {
  createAggregationSaga,
  loadAggregationsItemSaga,
  loadAggregationsListSaga,
  updateAggregationSaga,
} from './aggregation';
import {
  createDisaggregationSaga,
  loadDisaggregationItemSaga,
} from './disaggregation';
import {
  createMarkingSaga, loadMarkingItemSaga, loadMarkingListSaga, updateMarkingSaga,
} from './marking';
import {
  loadReportListSaga,
} from './reports';
import {
  createDeviceSaga, deleteDeviceItemSaga, loadDeviceItemSaga, loadDeviceListSaga,
} from './devices';
import { createStickerSaga, loadStickerItemSaga, loadStickerListSaga } from './stickers';
import { loadProfileSaga, updateDistributionSaga } from './participants';
import {
  createAllSPConnectionsSaga,
  deleteAllSPConnectionsSaga,
  loadServiceProvidersForConnectionSaga,
  loadServiceProvidersSaga,
} from './serviceProviders';
import {
  createServiceDistributionsSaga,
  loadServiceDistributionItemSaga,
  loadServiceDistributionsSaga, updateServiceDistributionsSaga,
} from './serviceDistributions';
import { loadValidationItemSaga } from './validation';

export function* clearErrorSaga() {
  yield put(clearErrors());
}

function* rootSaga() {
  yield all([
    // takeLatest(AGGREGATION.GET_LIST.START, loadAggregationListSaga),
    takeLatest(types.ORDERS_GET_LIST, loadOrderListSaga),
    takeLatest(types.ORDERS_GET_ITEM, loadOrderItemSaga),
    takeLatest(types.CLOSE_ORDER, closeOrderSaga),
    takeLatest(types.SEND_ORDER, sendOrderSaga),
    takeLatest(types.PRINT_ORDER_CODES, printOrderCodesSaga),
    takeLatest(types.CREATE_ORDER, createOrderSaga),
    takeLatest(types.ORDERS_GET_RETRY_LIST, getRetryCodeListSaga),
    takeLatest(types.REJECTION_GET_LIST, loadRejectionsListSaga),
    takeLatest(types.REJECTION_GET_ITEM, loadRejectionItemSaga),
    takeLatest(types.CREATE_REJECTION, createRejectionsSaga),
    takeLatest(types.UPDATE_REJECTION, updateDropoutSaga),
    takeLatest(types.AGGREGATION_GET_LIST, loadAggregationsListSaga),
    takeLatest(types.AGGREGATION_GET_ITEM, loadAggregationsItemSaga),
    takeLatest(types.DISAGGREGATION_GET_ITEM, loadDisaggregationItemSaga),
    takeLatest(types.VALIDATION_GET_ITEM, loadValidationItemSaga),
    takeLatest(types.CREATE_AGGREGATION, createAggregationSaga),
    takeLatest(types.UPDATE_AGGREGATION, updateAggregationSaga),
    takeLatest(types.CREATE_DISAGGREGATION, createDisaggregationSaga),
    takeLatest(types.MARKING_GET_LIST, loadMarkingListSaga),
    takeLatest(types.MARKING_GET_ITEM, loadMarkingItemSaga),
    takeLatest(types.GET_SETTINGS, loadSettingsSaga),
    takeLatest(types.CREATE_MARKING, createMarkingSaga),
    takeLatest(types.UPDATE_MARKING, updateMarkingSaga),
    takeLatest(types.LOGOUT, logoutSaga),
    takeLatest(types.LOGIN, loginSaga),
    takeLatest(types.CHECK_AUTH_DATA, checkAuthSaga),
    takeLatest(types.DOWNLOAD_FILE, downloadFileSaga),
    takeLatest(types.UPLOAD_FILE, uploadFileSaga),
    takeLatest(types.REPORT_GET_LIST, loadReportListSaga),
    takeLatest(types.DEVICES_GET_LIST, loadDeviceListSaga),
    takeLatest(types.DEVICES_GET_ITEM, loadDeviceItemSaga),
    takeLatest(types.DELETE_DEVICE, deleteDeviceItemSaga),
    takeLatest(types.CREATE_DEVICES, createDeviceSaga),
    takeLatest(types.STICKERS_GET_LIST, loadStickerListSaga),
    takeLatest(types.STICKERS_GET_ITEM, loadStickerItemSaga),
    takeLatest(types.CREATE_STICKERS, createStickerSaga),
    takeLatest(types.PARTICIPANT_GET_PROFILE, loadProfileSaga),
    takeLatest(types.PARTICIPANT_UPDATE_DISTRIBUTION, updateDistributionSaga),
    takeLatest(types.SP_GET_LIST, loadServiceProvidersSaga),
    takeLatest(types.DELETE_SP_CONNECTIONS, deleteAllSPConnectionsSaga),
    takeLatest(types.SP_GET_LIST_FOR_CONNECTION, loadServiceProvidersForConnectionSaga),
    takeLatest(types.CREATE_SP_CONNECTIONS, createAllSPConnectionsSaga),
    takeLatest(types.SD_GET_LIST, loadServiceDistributionsSaga),
    takeLatest(types.CREATE_SD, createServiceDistributionsSaga),
    takeLatest(types.SD_GET_ITEM, loadServiceDistributionItemSaga),
    takeLatest(types.UPDATE_SD, updateServiceDistributionsSaga),
  ]);
}

export default rootSaga;
